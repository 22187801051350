import splitArrayIntoChunksOfLen from "@/helpers/splitArrayIntoChunksOfLen";
import axios from "axios";
import {child, get, getDatabase, ref} from "firebase/database";
export default {
  state:{
    reserve: null,
    reserveOriginal:null,
    idsArr:null,
    isRLoading:{
      reserve:false,
      prices:false,
      ids:false,
    },
  },
  getters:{
    reserve:state=>state.reserve,
    reserveOriginal:state=>state.reserveOriginal,
    idsArr:state=>state.idsArr,
    isRLoading:state=>state.isRLoading,
  },
  mutations:{
    setReserve(state, {item}){
      state.reserve = item
    },
    setIdsArr(state, {arr}){
      state.idsArr = arr
    },
  },
  actions: {
    async loadTest({ dispatch, commit, getters, state, rootGetters, rootState}){
      console.log('loading test...')
      axios({
        url: 'https://lh3.googleusercontent.com/NPsbMi8ukViGB6MesbNDVHif8arQSYnN0zo87LyheAVgD4B93ae0TxEV58vvAbgsYOe4=w100',
        method: 'GET',
        responseType: 'blob'
      })
        .then((response) => {
          const url = window.URL
            .createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'image.jpg');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
    },

    async loadReserve({ dispatch, commit, getters, state, rootGetters, rootState},{id}){
      console.log('loading reserve...')
      state.isRLoading.reserve = true
      const uid = rootState.user.id
      const dbRef = await ref(getDatabase());
      await get(child(dbRef, `users/${uid}/reserve/${id}`)).then((snapshot) => {
        if (snapshot.exists()) {
          state.reserve = snapshot.val()
          // console.log('answer:',snapshot.val())
          // console.log('answer state:',state.loadedReserve)
        } else {
          this.commit('msgPush',{text:'no reserve data available', type:'warning'});
        }
      }).catch((error) => {
        console.error(error);
        this.commit('msgPush',{text:'loading reserve failed'+ error, type:'error'});
        throw error
      });
      state.isRLoading.reserve = false
    },
    async fetchIdsArr({ dispatch, commit, getters, state, rootGetters, rootState}){
      console.log('fetching idsArr...')
      state.isRLoading.ids = true
      const uid = rootState.user.id
      const dbRef = await ref(getDatabase());
      await get(child(dbRef, `/users/${uid}/reserve/ids`)).then((snapshot) => {
        if (snapshot.exists()) {
          state.idsArr = snapshot.val()
          // console.log('snapshot:',snapshot.val())
        }else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
        this.commit('msgPush',{text:'reserves ids loading failed'+ error, type:'error'});
        throw error
      });
      state.isRLoading.ids = false
    },

    async refreshPrices({ dispatch, commit, getters, state }) {
      if(state.reserveOriginal){
        console.log('resetting prices...')
        state.reserve = state.reserveOriginal
        state.reserveOriginal = null
        return
      }
      state.isRLoading.prices = true;
      state.reserveOriginal = JSON.parse(JSON.stringify(state.reserve))//сохр необновленную версию для будущ возврата
      const tradeListIds = state.reserve[getters.current.infoName].tradeList.map(item=>item.id);
      const trashListIds = state.reserve[getters.current.infoName].trashList.map(item=>item.id);
      const all = [...tradeListIds,...trashListIds];//обьединяем все id в один массив
      const allCleaned = [...new Set(all)];//почистить массив от повторов если они имеются
      const splitedArr = splitArrayIntoChunksOfLen(allCleaned,50); //делим на порции ибо CoinGecko шлет макс 50 за один запрос
      const idsString0 = splitedArr[0].join('%2C');//создаем строку для запроса
      const idsString1 = splitedArr[1]?splitedArr[1].join('%2C'):'';//создаем строку для запроса
      const idsString2 = splitedArr[2]?splitedArr[2].join('%2C'):'';//создаем строку для запроса

      //создаем запрос
      let valuesArr = [
        axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${idsString0}&order=market_cap_desc&per_page=50&page=1&sparkline=true`),
        axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${idsString1}&order=market_cap_desc&per_page=50&page=1&sparkline=true`),
        axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${idsString2}&order=market_cap_desc&per_page=50&page=1&sparkline=true`),
      ]
      return axios.all(valuesArr)
        .then(axios.spread((res0, res1,res2) => {
          const geckoList = [...res0.data,...res1.data,...res2.data]
          // console.log('geckoList', geckoList)

          const listsToRefresh = ['tradeList','trashList']
          listsToRefresh.forEach((itemListName,index,arr)=>{
            state.reserve[getters.current.infoName][itemListName] = state.reserve[getters.current.infoName][itemListName].map(item => {
              const coinObjInGecko = geckoList.find(geckoitem => geckoitem.symbol === item.coin);
              if (coinObjInGecko) {//если найдена моя монета в моей базе то дополняем сущ лист ценами с базы. Если не найдена то ничего не дел.
                // console.log('addFreshPrices, if coinObjInGecko:', coinObjInGecko);
                return {
                  ...item,
                  currentPrice: coinObjInGecko.current_price,//кажд раз обновление.
                  priceChangePercentage24h: Math.round(coinObjInGecko.price_change_percentage_24h),
                  // valueNow: +(item.totalAmount * coinObjInGecko.current_price).toFixed(5),//кажд раз пересчет.
                  // profit: +(item.totalAmount * coinObjInGecko.current_price).toFixed(5) - item.invested,//кажд раз пересчет.
                  marketCapRank: coinObjInGecko.market_cap_rank,
                  sparklineIn7d: coinObjInGecko.sparkline_in_7d.price,
                }
              } else return item
            })
          })
        }))
        .catch((err) => {
          console.log(err)
          this.commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
        })
        .then(()=>{
          state.isRLoading.prices = false;
        })
    },
  }
}
