import splitArrayIntoChunksOfLen from "@/helpers/splitArrayIntoChunksOfLen";
import axios from "axios";
import {child, get, getDatabase, ref} from "firebase/database";
import {readableNumber} from "@/mixins/readableNumber.mixin";
import generateRandomId from "@/helpers/generateRandomId";
// import reserveModule from "@/store/crypto/reserveModule";
// import cryptoModule from "@/store/crypto/cryptoModule";
export default {
  // modules:{
  //   reserveModule,
  //   cryptoModule,
  // },
  state:{
    isGeckoLoadingList:false,
    isGeckoLoadingGlobal:false,
    totalCoinsAmount:null,
    totalCoinsAmountCleaned:null,
    totalCoinsReceived:null,
    updateDate:null,
    allCoins:[],
    myGeckoList:[],
    global:null,
    btcPrice:null,
    ethPrice:null,
  },
  getters:{
    isGeckoLoadingList:state=>state.isGeckoLoadingList,
    totalCoinsAmount:state=>state.totalCoinsAmount,
    totalCoinsAmountCleaned:state=>state.totalCoinsAmountCleaned,
    totalCoinsReceived:state=>state.totalCoinsReceived,
    updateDate:state=>state.updateDate,
    allCoins:state=>state.allCoins,
    myGeckoList:state=>state.myGeckoList,
    global:state=>state.global,
    btcPrice:state=>state.btcPrice,
    ethPrice:state=>state.ethPrice,
  },
  mutations:{
    updateBunch(state){
      this.commit('updateListWithMyGecko', {listName:'tradeList',geckoList:state.myGeckoList})
      this.commit('updateListWithMyGecko', {listName:'tradeList',info:'infoAK',geckoList:state.myGeckoList})
      this.commit('updateListWithMyGecko', {listName:'trashList',geckoList:state.myGeckoList})
      this.commit('updateListWithMyGecko', {listName:'hypLists',geckoList:state.myGeckoList})
      this.commit('updateListWithMyGecko', {listName:'lastActivityList',geckoList:state.myGeckoList})
      this.commit('updateListWithMyGecko', {listName:'potentialList',geckoList:state.myGeckoList})
      this.commit('updateListWithMyGecko', {listName:'potentialList',info:'infoAK',geckoList:state.myGeckoList})
      this.commit('updateListWithMyGecko', {listName:'alarmAltsList',geckoList:state.myGeckoList})
      this.commit('updateListWithMyGecko', {listName:'alarmAltsList',info:'infoAK',geckoList:state.myGeckoList})
      // this.commit('updateAlarmAltsListWithMyGecko', {info:'infoMain',geckoList:geckoList})
      // this.commit('updateAlarmAltsListWithMyGecko', {info:'infoAK',geckoList:geckoList})
    }
  },
  actions: {
    async loadBtcAndEth({ dispatch, commit, getters, rootGetters, state,rootState },currency='usd') {//загрузить инфо только моих монет
      state.isGeckoLoading = true;
        //создаем запрос
        axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=ethereum%2Cbitcoin&order=market_cap_desc&sparkline=true`)
          .then((res0)=>{
            const geckoList = res0.data;
            state.btcPrice = res0.data.find(item=>item.id==='bitcoin').current_price.toFixed(0)
            state.ethPrice = res0.data.find(item=>item.id==='ethereum').current_price.toFixed(0)
          })
          .catch((err) => {
            console.log(err)
            commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
          })
          .then(()=>{
            state.isGeckoLoading = false;
          })
    },
    async loadAllGeckoCoins({ dispatch, commit, getters, rootGetters, state }){
      const options = {
        method: 'GET',
        url: 'https://api.coingecko.com/api/v3/coins/list',
        headers: {accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}
      };

      return axios
        .request(options)
        .then((res)=>{
          // console.log('loadAllGeckoCoins:',res.data.length)
          state.allCoins = res.data;
          console.log('loadAllGeckoCoins:',new TextEncoder().encode(JSON.stringify(res.data)).length /1024)
        })
        .catch((err) => {
          console.log('errorRRR from actions loadAllGeckoCoins:', err);
          commit('msgPush', { text:'allcoins adding error',type:'error'});
        })
      // return axios.get('https://api.coingecko.com/api/v3/coins/list')
      //   .then((res)=>{
      //     // console.log('loadAllGeckoCoins:',res.data.length)
      //     state.allCoins = res.data;
      //   })
      //   .catch((err) => {
      //     console.log('errorRRR from actions loadAllGeckoCoins:', err);
      //     commit('msgPush', { text:'allcoins adding error',type:'error'});
      //   })
    },

    async loadGlobal({ dispatch, commit, getters,state }){
      const options = {
        method: 'GET',
        url: 'https://api.coingecko.com/api/v3/global',
        headers: {accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}
      };
      state.isGeckoLoadingGlobal = true;
      return axios
        .request(options)
        .then(res => {
              // console.log('loadGlobal:',res.data.data)
              state.global = res.data.data;
              state.isGeckoLoadingGlobal = false;
        })
        .catch(err => {
              console.log('errorRRR from actions loadGlobal:', err);
              commit('msgPush', { text:'loadGlobal error',type:'error'});
              state.isGeckoLoadingGlobal = false;
              // console.error(err)
        });
      // return axios.get('https://api.coingecko.com/api/v3/global')
      //   .then((res)=>{
      //     // console.log('loadGlobal:',res.data.data)
      //     state.global = res.data.data;
      //     state.isGeckoLoadingGlobal = false;
      //     console.log('loadGlobal:',state.isGeckoLoadingGlobal)
      //   })
      //   .catch((err) => {
      //     console.log('errorRRR from actions loadGlobal:', err);
      //     commit('msgPush', { text:'loadGlobal error',type:'error'});
      //     state.isGeckoLoadingGlobal = false;
      //     console.log('loadGlobal:',state.isGeckoLoadingGlobal)
      //   })
    },

    async loadAlt({ dispatch, commit, getters, state, rootState },{item}) {
      const isInBase = state.allCoins.find(i => i.symbol === item.coin);
      const isInCurrentList = getters.currentList.find(i => i.coin === item.coin);
      const btcPrice = state.myGeckoList?.find(i=>i.symbol==='btc').current_price
      if(isInCurrentList){
        return alert('уже есть в этом списке. Игнор')
      }
      if (!isInBase && item.manualId==='') {
        return alert('Нет такой монеты в базе. Игнор')
      }
      const coinId = item.manualId || isInBase.id;
      state.isGeckoLoading = true;
      const options = {
        method: 'GET',
        url: `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${coinId}&order=market_cap_desc&per_page=250&page=1&sparkline=true`,
        headers: {accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}
      };

      return axios
        .request(options)
          .then((res) => {
            const finalItem = {
              id: res.data[0].id,
              exchange: item.exchange,
              coin: item.coin,
              coinUrl: res.data[0].image,
              desiredPrice: item.price,
              currentPrice: res.data[0].current_price,
              fixedProfit: 0,
              comment: item.comment,
              name: res.data[0].name,
              priceChangePercentage24h:Math.round(res.data[0].price_change_percentage_24h),
              sparklineIn7d: getters.current.listName==='hypLists'?'':res.data[0].sparkline_in_7d.price.map(n => readableNumber(n)),
              trades: [
                {
                  amount: item.amount||1,
                  price: item.inBtc?item.price*btcPrice:item.price,
                  comment: item.comment,
                  id: item.id,
                  date: item.date,
                  margin:item.margin,
                  ls:item.ls,
                },
              ],
            }
            if(getters.current.listName === 'tradeList'){ //activity
              rootState.cryptoModule[getters.current.infoName].lastActivityList.unshift(//добавляем activity запись в начало
                {
                  amount:item.amount,
                  id:generateRandomId(),
                  date:item.date,
                  comment:item.comment,
                  coin:item.coin,
                  price:item.price,
                  currentPrice:res.data[0].current_price,
                  coinUrl:res.data[0].image,
                  name:res.data[0].name,
                  margin:item.margin,
                  ls:item.ls,
                }
              )
              dispatch('updateFBInfoList',{listName:'lastActivityList'});
            }

            if(getters.current.listName === 'hypLists'){
              rootState.cryptoModule.hypLists = rootState.cryptoModule.hypLists.map(i=> {
                if(i.title === getters.current.listNameSub){
                  return {
                    ...i,
                    hypTradeList:[...i.hypTradeList,finalItem]
                  }
                }else return i
              })
              dispatch('updateFBPath',{path:'hypLists',data:rootState.cryptoModule.hypLists});
            }else{
              rootState.cryptoModule[getters.current.infoName][getters.current.listName].push(finalItem)//learn.epam.com. luxoft
              dispatch('updateFBInfoList',{listName:getters.current.listName});
            }
              // commit('updateListWithMyGecko', {listName:getters.current.listName, geckoList:state.myGeckoList})
              // commit('calculate',{coin:item.coin})
              commit('msgPush', { text:'trade '+item.coin+ ' added', type:'success'});
            // }
          })
          .catch((err) => {
            console.log('errorRRR from actions loadAlt:', err);
          })
          .then(()=>{
            state.isGeckoLoading=false;
          })
    },

    async loadMyGeckoCoinsList({ dispatch, commit, getters, rootGetters, state,rootState },{currency='usd',listName}) {
      const tradeList = getters.infoMain.tradeList.map(item=>item.id);
      const tradeListAK = getters.infoAK.tradeList.map(item=>item.id);
      const trash = getters.infoMain.trashList.map(item=>item.id);
      const trashAK = getters.infoAK.trashList.map(item=>item.id);
      const potential = getters.infoMain.potentialList.map(item=>item.id);
      const potentialAK = getters.infoAK.potentialList.map(item=>item.id);
      let all
      console.log(listName)
      if(listName === 'tradeList'){
        state.isGeckoLoadingList = true;
        all = [...tradeList,...tradeListAK];
      }else if(listName === 'potentialList'){
        state.isGeckoLoadingList = true;
        all = [...potential,...potentialAK];
      }else if(listName === 'trashList'){
        state.isGeckoLoadingList = true;
        all = [...trash,...trashAK];
      }else {
        state.isGeckoLoading = true;
        all = [...tradeList,...tradeListAK,...potential,...potentialAK,...trash,...trashAK];
      }//обьединяем все id в один массив
      const allCleaned = [...new Set(all)];
      state.totalCoinsAmount = all.length;
      state.totalCoinsAmountCleaned = allCleaned.length;

      const splitedArr = splitArrayIntoChunksOfLen(allCleaned,99); //делим на порции ибо CoinGecko шлет макс 50 за один запрос. Походу поменялось на 100.
      // console.log('splitedArr.length:',splitedArr.length)

      if(splitedArr.length===1) {
        const idsString0 = splitedArr[0].join('%2C');//создаем строку для запроса
        //создаем запрос
        const options = {
          method: 'GET',
          url: `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=ethereum%2Cbitcoin%2C${idsString0}&order=market_cap_desc&sparkline=true`,
          headers: {accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}
        };

        return axios
          .request(options)
          .then((res0)=>{
            const geckoList = res0.data;
            // console.log('jjj',res0.data.find(item=>item.id==='bitcoin').current_price)
            // state.btcPrice = res0.data.find(item=>item.id==='bitcoin').current_price
            // state.ethPrice = res0.data.find(item=>item.id==='ethereum').current_price
            state.myGeckoList = geckoList;
            state.totalCoinsReceived = geckoList.length;
            this.commit('updateBunch')
            // this.commit('updateBunch',{geckoList})
            state.updateDate = new Date()
            console.log('запрос arr №1')
          })
          .catch((err) => {
            console.log('err1:',err)
            commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
          })
          .then(()=>{
            state.isGeckoLoadingList=false
          })
      }
      if(splitedArr.length===2) {
        const idsString0 = splitedArr[0].join('%2C');//создаем строку для запроса
        const idsString1 = splitedArr[1] ? splitedArr[1].join('%2C') : '';//создаем строку для запроса
        let valuesArr = [
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=ethereum%2Cbitcoin%2C${idsString0}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString1}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
        ]
        return axios.all(valuesArr)
          .then(res => {
            const geckoList = [...res[0].data,...res[1].data]
              // const newList = geckoList.map(item=>{
              //   const newItems = item.sparkline_in_7d.map(item2=> {
              //     return {
              //       ...item2,
              //       sparklineIn7d: getters.current.listName==='hypLists'?'':res.data[0].sparkline_in_7d.price.map(n => readableNumber(n)),
              //     }
              //   })
              //   return {
              //     ...item,
              //     sparklineIn7d: getters.current.listName==='hypLists'?'':res.data[0].sparkline_in_7d.price.map(n => readableNumber(n)),
              //   }
              // })

            state.myGeckoList = geckoList;
            state.totalCoinsReceived = geckoList.length;
            this.commit('updateBunch')
            state.updateDate = new Date()
            console.log('запрос arr №2')
            console.log('loadMyGeckoCoinsList:',new TextEncoder().encode(JSON.stringify(geckoList)).length /1024)
          })
          .catch((err) => {
            console.log('err2:',err)
            commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
          })
          .then(()=>{
            state.isGeckoLoadingList=false
          })
      }
      if(splitedArr.length===3) {
        const idsString0 = splitedArr[0].join('%2C');//создаем строку для запроса
        const idsString1 = splitedArr[1]?splitedArr[1].join('%2C'):'';//создаем строку для запроса
        const idsString2 = splitedArr[2]?splitedArr[2].join('%2C'):'';//создаем строку для запроса
        let valuesArr = [
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=ethereum%2Cbitcoin%2C${idsString0}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString1}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString2}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
        ]
        return axios.all(valuesArr)
          .then(axios.spread((res0, res1,res2) => {
            const geckoList = [...res0.data,...res1.data,...res2.data];
            state.myGeckoList = geckoList;
            state.totalCoinsReceived = geckoList.length;
            this.commit('updateBunch')
            state.updateDate = new Date()
            console.log('запрос arr №3')
          }))
          .catch((err) => {
            console.log('err3:',err)
            commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
          })
          .then(()=>{
            state.isGeckoLoadingList=false
          })
      }
      if(splitedArr.length===4) {
        const idsString0 = splitedArr[0].join('%2C');//создаем строку для запроса
        const idsString1 = splitedArr[1]?splitedArr[1].join('%2C'):'';//создаем строку для запроса
        const idsString2 = splitedArr[2]?splitedArr[2].join('%2C'):'';//создаем строку для запроса
        const idsString3 = splitedArr[3]?splitedArr[3].join('%2C'):'';//создаем строку для запроса
        let valuesArr = [
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=ethereum%2Cbitcoin%2C${idsString0}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString1}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString2}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString3}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
        ]
        return axios.all(valuesArr)
          .then(axios.spread((res0, res1,res2,res3) => {
            const geckoList = [...res0.data,...res1.data,...res2.data,...res3.data];
            state.myGeckoList = geckoList;
            state.totalCoinsReceived = geckoList.length;
            this.commit('updateBunch')
            state.updateDate = new Date()
            console.log('запрос arr №4')
          }))
          .catch((err) => {
            console.log('err4:',err)
            commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
          })
          .then(()=>{
            state.isGeckoLoadingList=false
          })
      }
      if(splitedArr.length===5) {
        const idsString0 = splitedArr[0].join('%2C');//создаем строку для запроса
        const idsString1 = splitedArr[1]?splitedArr[1].join('%2C'):'';//создаем строку для запроса
        const idsString2 = splitedArr[2]?splitedArr[2].join('%2C'):'';//создаем строку для запроса
        const idsString3 = splitedArr[3]?splitedArr[3].join('%2C'):'';//создаем строку для запроса
        const idsString4 = splitedArr[4]?splitedArr[4].join('%2C'):'';//создаем строку для запроса
        let valuesArr = [
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=ethereum%2Cbitcoin%2C${idsString0}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString1}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString2}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString3}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString4}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
        ]
        return axios.all(valuesArr)
          .then(axios.spread((res0, res1,res2,res3,res4) => {
            const geckoList = [...res0.data,...res1.data,...res2.data,...res3.data,...res4.data];
            state.myGeckoList = geckoList;
            state.totalCoinsReceived = geckoList.length;
            this.commit('updateBunch')
            state.updateDate = new Date()
            console.log('запрос arr №5')
          }))
          .catch((err) => {
            console.log('err5:',err)
            commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
          })
          .then(()=>{
            state.isGeckoLoadingList=false
          })
      }
      if(splitedArr.length===6) {
        const idsString0 = splitedArr[0].join('%2C');//создаем строку для запроса
        const idsString1 = splitedArr[1]?splitedArr[1].join('%2C'):'';//создаем строку для запроса
        const idsString2 = splitedArr[2]?splitedArr[2].join('%2C'):'';//создаем строку для запроса
        const idsString3 = splitedArr[3]?splitedArr[3].join('%2C'):'';//создаем строку для запроса
        const idsString4 = splitedArr[4]?splitedArr[4].join('%2C'):'';//создаем строку для запроса
        const idsString5 = splitedArr[5]?splitedArr[5].join('%2C'):'';//создаем строку для запроса
        //была ошибка 50/1: '...market_cap_desc&per_page=50&page=1&sparkline=true'
        let valuesArr = [
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=ethereum%2Cbitcoin%2C${idsString0}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString1}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString2}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString3}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString4}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString5}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
        ]
        return axios.all(valuesArr)
          .then(axios.spread((res0, res1,res2,res3,res4,res5) => {
            const geckoList = [...res0.data,...res1.data,...res2.data,...res3.data,...res4.data,...res5.data];
            state.myGeckoList = geckoList;
            state.totalCoinsReceived = geckoList.length;
            this.commit('updateBunch')
            state.updateDate = new Date()
            console.log('запрос arr №6')
          }))
          .catch((err) => {
            console.log('err6:',err)
            // commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
          })
          .then(()=>{
            state.isGeckoLoadingList=false
          })
      }
      if(splitedArr.length===7) {
        const idsString0 = splitedArr[0].join('%2C');//создаем строку для запроса
        const idsString1 = splitedArr[1]?splitedArr[1].join('%2C'):'';//создаем строку для запроса
        const idsString2 = splitedArr[2]?splitedArr[2].join('%2C'):'';//создаем строку для запроса
        const idsString3 = splitedArr[3]?splitedArr[3].join('%2C'):'';//создаем строку для запроса
        const idsString4 = splitedArr[4]?splitedArr[4].join('%2C'):'';//создаем строку для запроса
        const idsString5 = splitedArr[5]?splitedArr[5].join('%2C'):'';//создаем строку для запроса
        const idsString6 = splitedArr[6]?splitedArr[6].join('%2C'):'';//создаем строку для запроса
        let valuesArr = [
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=ethereum%2Cbitcoin%2C${idsString0}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString1}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString2}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString3}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString4}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString5}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
          await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=${idsString6}&order=market_cap_desc&sparkline=true`,{headers:{accept: 'application/json', 'x-cg-demo-api-key': 'CG-x1Vw6vEP6M7JMtArpBhdfhnj'}}),
        ]
        return axios.all(valuesArr)
          .then(axios.spread((res0, res1,res2,res3,res4,res5,res6) => {
            const geckoList = [...res0.data,...res1.data,...res2.data,...res3.data,...res4.data,...res5.data,...res6.data];
            state.myGeckoList = geckoList;
            state.totalCoinsReceived = geckoList.length;
            this.commit('updateBunch')
            state.updateDate = new Date()
          }))
          .catch((err) => {
            console.log('err7:',err)
            commit('msgPush',{text:'loadMyGeckoCoins' + err, type:'error'});
          })
          .then(()=>{
            state.isGeckoLoadingList=false
          })
      }
    },
  }
}
