<template>
  <div class="upButton hover" :class="{'hide':get_scroll_position()<=200}">
    <img
      src="/img/upW.png"
      alt="up button"
      @click="up"
    />
  </div>
</template>

<script>
  import {defineComponent} from "vue";
  import scrollPosition from "@/mixins/scrollPosition.mixin";
  export default defineComponent( {
    mixins:[scrollPosition],
    methods: {
      up(){
        window.scrollTo({top: 0, behavior: 'smooth'});
      },
    },
  })
</script>

<style scoped>
  .upButton{
    position: fixed;
    bottom:50px;
    right:20px;
    width:40px;
    height:40px;
  }
  .hide{
    display: none;
  }
  .upButton img{
    width:100%;
  }
  @media only screen and (max-width: 768px) {
  }

</style>

