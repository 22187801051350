<template>
  <section class="userBox myWhite" >
    <div class="avatarBox mr10">
<!--      <img class="avatarImg" :src="this.$store.getters.user.photoURL" alt="user avatar"/>-->
      <LazyImg :url="this.$store.getters.user.photoURL" size="50" round="true"/>
<!--      <LazyImg :url="this.$store.getters.user.photoURL" urlblur="/img/cosmo.png" size="50" round="true"/>-->
    </div>
    <div class="username">
      <span>{{$store.getters.user.displayName}}</span>
    </div>
  </section>
</template>

<script>
  import LazyImg from "@/components/LazyImg";
  export default {
    components:{LazyImg},
    data() {
      return {
      };
    },
    methods: {
      // clickLetter(){
      //   this.$store.commit('switchInfo')
      //   this.$store.commit('switchAK')
      // },
    },
    mounted() {
    }
  }
</script>

<style scoped>
  .userBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatarBox{
    align-items: center;
    text-align: center;
    display: flex;
    z-index: 1000;
    border:1px solid rgba(0,0,0,0.2);
    border-radius: 50%;
  }
  .avatarBox:hover{
    cursor: pointer;
  }
  .username {
    line-height: 50px;;
  }
  .username:hover {
    color: #F8CC2D;
    cursor: pointer;
  }
  .avatarBox:hover {
    cursor: pointer;
    color: #F8CC2D;
    border:1px solid #F8CC2D;
  }

  .avatarImg {
    width:50px;
    border-radius: 50%;
  }

 @media only screen and (max-width: 768px) {
   /*.userBox {*/
   /*  top:15px;*/
   /*  right:30px;*/

   /*}*/

  }

</style>

