<template>
  <section>
    <span v-if="!$store.getters.isGeckoLoading">
      <v-progress-circular
        :model-value="progress"
        color="grey"
        :size="35"
        :width="3"
      >
      </v-progress-circular>
    </span>
  </section>
</template>

<script>
  import {defineComponent} from "vue";
  export default defineComponent( {
    props:[],
    data: () => ({
      interval:null,//in mounted we record this interval
      progress:null,
    }),

    mounted () {
      this.interval = setInterval(()=>{
        this.progress += 1
      },this.$store.getters.currentInfo.service.refreshCoinsInterval/100)
    },
    beforeUnmount () {//очистить обновление времени из памяти при исчезновении панели Navbar
      clearInterval(this.interval)
    },

  })
</script>

<style scoped>

  @media only screen and (max-width: 768px) {

  }

</style>
