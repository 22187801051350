export default function (exchangeName,coinName){
  if(exchangeName==='Binance'){
    return `https://www.binance.com/en/trade/${coinName}_USDT?layout=pro&theme=dark&type=spot`
  }
  if(exchangeName==='Ascendex'){
    return `https://ascendex.com/en/cashtrade-spottrading/usdt/`+ coinName
  }
  if(exchangeName==='Kucoin'){
    return `https://trade.kucoin.com/${coinName.toUpperCase()}-USDT`
  }
  if(exchangeName==='Gate'){
    return `https://www.gate.io/en/trade/${coinName.toUpperCase()}_USDT`
  }
  if(exchangeName==='Hitbtc'){
    return `https://hitbtc.com/${coinName}-to-usdt`
  }
  if(exchangeName==='Bkex'){
    return `https://www.bkex.com/trade/${coinName.toUpperCase()}_USDT`
  }
  return `https://www.coingecko.com/en/coins/${coinName}`
}

