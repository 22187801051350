<template>
  <section class="form myWhite">
    <v-row justify="center" class="mb20 signedIn fz14">
      signed in as:
    </v-row>
    <v-row justify="center" class="mb10 myWhite mb20">
      {{$store.getters.user.email}}
    </v-row>
    <v-row justify="center" class="mb10 myWhite">
      <v-btn @click="logout"  variant="text" size="x-small" theme="dark" color="yellow">
        logout
      </v-btn>
    </v-row>
  </section>
</template>

<script>
  export default {
    props:['isOpen'],
    data() {
      return {
      };
    },
    methods: {
      logout(){
        this.$store.dispatch('logout')
        this.$emit('update:isOpen',false)
      },
    },
    watch: {
    },
  };
</script>


<style scoped>
  .form{
    width:250px;
    height:100vh;
    padding:40px 10px 10px 10px;
    border-left:1px solid rgba(255,255,255,0.2);
  }
  .signedIn{
    color:#F8CC2D
  }
  @media only screen and (max-width: 768px) {

  }

</style>

