<template>
  <section>
    <v-form v-model="valid" class="form" @submit.prevent="toSubmit" >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
            v-if="model==='switch to login'"
          >
            <v-text-field
              v-model.trim="name"
              :rules="nameRules"
              label="Name"
              required
              bg-color="white"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model.trim="email"
              :rules="emailRules"
              type="email"
              label="E-mail"
              bg-color="white"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model.trim="password"
              label="Password"
              type="password"
              required
              bg-color="white"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" >
            <v-switch
              v-model="model"
              hide-details
              true-value="switch to login"
              false-value="switch to register"
              :label="model"
              class="label"
              style="color:white"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="12" align="center">
            <v-btn type="submit" size="small" class="mt20 mr20 fc" theme="dark" color="yellow">
              {{model==='switch to login'?'Register':'Login'}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            test account:
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            login: test@yandex.ru
            password: 111111
          </v-col>
        </v-row>
      </v-container>
    </v-form>

  </section>
</template>

<script>
  export default {
    props:['isOpen'],
    data() {
      return {
        model:'switch to register',
        isRegisterForm:true,
        name: '',
        valid:false,
        password: '',
        nameRules: [
          v => !!v || 'Name is required',
          v => v.length <= 10 || 'Name must be less than 10 characters',
        ],
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],

      };
    },
    methods: {
      toSubmit(){
        this.$store.dispatch(
          this.model==='switch to login'?'register':'login',
          {
            email:this.email,
            password:this.password,
            name:this.name
          });
        this.$emit('update:isOpen',false)
      },
      // async toLogin(){
      //   try{
      //     await this.$store.dispatch('login',this.form);
      //     this.$router.go()
      //   }catch(error){}
      // },
      // async toLogout(){
      //   try{
      //     await this.$store.dispatch('logout');
      //     this.$router.go()
      //   }catch(error){}
      // },
      // async toRegister(){
      //   try{
      //     await this.$store.dispatch('register',this.form);
      //     this.form.email=null;
      //     this.form.password=null;
      //     this.form.name=null;
      //     this.isRegisterForm=false;
      //     await this.$emit('update:open', false)
      //   }catch(error){}
      //     // await this.$store.commit('msgFB',{text:'toRegister is failed'});
      //   // console.log('catch error')
      // },
    },
  };
</script>


<style scoped>
  .form{
    width:250px;
    height:100vh;
    padding:40px 10px 10px 10px;
  }
  .loginBtn {
    border:none;
    /*width: 50px;*/
    /*height: 20px;*/
    background-color: #F8CC2D;
    border-radius: 5%;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
    padding:5px;
  }
  .loginBtn:hover {
    cursor: pointer;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
  .input {
    background-color: rgba(255, 255, 255, 0.95);
  }
  .backLink{
    color: #F8CC2D;
    font-size: 12px;
    margin:0 auto;
  }
  .backLink:hover{
    cursor: pointer;
    border-bottom: 1px solid #F8CC2D;
  }
  @media only screen and (max-width: 768px) {

  }

</style>
