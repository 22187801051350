import {createUserWithEmailAndPassword, getAuth, sendEmailVerification, signInWithEmailAndPassword, updateProfile} from "firebase/auth"
import { getDatabase, ref, child, get, onValue, push, update } from "firebase/database";
import getIdAK from '../helpers/getIdAK';
import router from "@/router";

export default {
  actions: {
    async login({ dispatch,commit},{email,password}){
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          commit('setUser',user ? user : null);
          router.push('/')
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          commit('msgPush', { text:errorMessage, type:'error'});
        });
    },
    async logout({ dispatch,commit }){
      try{
        await getAuth().signOut()
        // await commit('clearAllInfo')
        //зачистка не все под ноль а как будто приходят пустые данные, иначе все ломается
        await commit('setLvl',{ lvl1:'current',lvl2:'infoName',data:'infoMain'})
        await commit('setLvl',{ lvl1:'current',lvl2:'setCurrentList',data:'tradeList'})
        // await commit('setCurrentInfo',{ infoName:'infoMain'})
        // await commit('setCurrentList',{ listName:'tradeList'})
        await commit('resetUser')
        await commit('resetInfo', {info:'infoMain'})
        await commit('resetInfo', {info:'infoAK'})
        await router.push('/login')
        // await commit('msgPush',{text:'logged out',type:'success'});
      }catch(error){}
    },
    async register({ dispatch,commit },{email,password,name}){
      console.log('register...')
      const auth = getAuth();
      // console.log('auth',auth)
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // console.log('user:', user)
          const userWithName = {...user, displayName: name}
          commit('setUser',user ? userWithName : null)
          updateProfile(auth.currentUser, {
            displayName: name,
          })
          sendEmailVerification(auth.currentUser)
            .then(() => {
              console.log('Email verification sent!')
              commit('msgPush',{text:'Email verification sent!', type:'info'});
            });
          router.push('/')
          commit('msgPush',{text:'welcome ' + name, type:'success'});
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('errorMessage',errorMessage)
          commit('msgPush',{text:errorMessage, type:'error'});
          // ..
        });
      // try{
      //   await getAuth().createUserWithEmailAndPassword(email,password)
      //   await commit('msgFB',{text:'new user is registered'});
      // }catch(error){
      //   console.log('throw error:',error)
      //   throw error
      // }
    },

    async fetchFBInfo(context){
      console.log('fetchFBInfo...')
      try{
        // const user = await getAuth().currentUser
        // await context.commit('setUser',user ? user : null);
        // if(!user){return console.log('no user, return...')}
        const user = context.getters.user// user.uid -> user.id
        const dbRef = ref(getDatabase());
        //FB my
        await get(child(dbRef, `users/${user.id}/info`)).then((snapshot) => {
          if (snapshot.exists()) {
            // console.log(snapshot.val())
            context.commit('setInfo', { data:snapshot.val(),info:'infoMain'})
          } else {
            console.log("No main data available.");
          }
        }).catch((error) => {
          console.error('er:',error);
          context.commit('msgPush', { text:'fetchInfo er:->'+error, type:'error'});
          // setTimeout(this.logout,3000)
          getAuth().signOut()
          router.push('/login')
        });
        //FB AK
        if(user.isAorK){//если левый акк то не выполнять
          await get(child(dbRef, `users/${getIdAK(user.id)}/info`)).then((snapshot) => {
            if (snapshot.exists()) {
              // console.log(snapshot.val())
              context.commit('setInfo', { data:snapshot.val(),info:'infoAK'})
            } else {
              console.log("No AK data available.");
            }
          }).catch((error) => {
            console.log('error.....');
            console.error(error);
            // setTimeout(this.fetchFBInfo,8000)
          });
        }
      }catch(error){
        context.commit('msgPush',{text:'fetching infoFB error:' + error, type:'error'});
        throw error
      }
    },
    async fetchFBInfoA(context){
      console.log('fetchFBInfoA...')
      try{
        const user = context.getters.user// user.uid -> user.id
        const dbRef = ref(getDatabase());
        await get(child(dbRef, `users/${user.id}/info`)).then((snapshot) => {
          if (snapshot.exists()) {
            // console.log(snapshot.val())
            context.commit('setInfo', { data:snapshot.val(),info:'infoMain'})
          } else {
            console.log("No main data available.");
          }
        }).catch((error) => {
          console.log('error.....');
          console.error(error);
          // setTimeout(this.fetchFBInfo,8000)
          context.commit('msgPush',{text:'get infoFB error:' + error, type:'error'});
        });
      }catch(error){
        context.commit('msgPush',{text:'try fetch infoFB error:' + error, type:'error'});
        throw error
      }
    },
    async fetchList(context, {listName, isAK}){
      console.log('fetching '+listName+'...')
      try{
        // const user = await getAuth().currentUser
        const user = context.getters.user// user.uid -> user.id
        const dbRef = ref(getDatabase());
        await get(child(dbRef, `users/${isAK?getIdAK(user.id):user.id}/info/`+listName)).then((snapshot) => {
          if (snapshot.exists()) {
            // console.log(snapshot.val())
            context.commit('setList', { data:snapshot.val(),listName, toAK:isAK})
          } else {
            console.log("No list available");
          }
        }).catch((error) => {
          console.error(error);
        });
      }catch(error){
        context.commit('msgPush',{text:'fetching list error:' + error, type:'error'});
        throw error
      }
    },
    async fetchFBPath(context, {path, stateLvl1,stateLvl2,stateLvl3,stateLvl4}){
      console.log('fetching path' )
      try{
        // const user = await getAuth().currentUser
        const user = context.getters.user// user.uid -> user.id
        const dbRef = ref(getDatabase());
        await get(child(dbRef, `users/${user.id}/`+ path)).then((snapshot) => {
          if (snapshot.exists()) {
            // console.log(snapshot.val())
            context.commit('setLvl', { data:snapshot.val(),lvl1:stateLvl1,lvl2:stateLvl2,lvl3:stateLvl3,lvl4:stateLvl4})
            if(path==='hypLists'){
              const hypTitles = context.getters.hypLists.map(item => item.title)
              context.commit('setLvl', {lvl1:'current',lvl2:'listNameSub', data: hypTitles[hypTitles.length-1] || null})
              // context.commit('setCurrentListNameSub', {listNameSub: hypTitles[hypTitles.length-1] || null})
              context.dispatch('loadMyGeckoCoinsList',{listName:'tradeList'});
            }
          } else {
            console.log("No path available");
          }
        }).catch((error) => {
          console.error(error);
        });
      }catch(error){
        context.commit('msgPush',{text:'fetching path error:' + error, type:'error'});
        throw error
      }
    },
    // async updateFB({ dispatch,commit,getters,rootState}, {name, path, data}){
    //   // try{
    //   //   const uid = getters.user.id
    //   //   const db = await getDatabase();
    //   //   // const newPostKey = push(child(ref(db), `/users/${getters.isAK?getIdAK(uid):uid}/info`)).key;
    //   //   const updates = {};
    //   //   if(path && data){
    //   //     updates[`/users/${getters.user.isSwitched?getIdAK(uid):uid}/info/` + path] = data;
    //   //   }
    //   //   if(name){
    //   //     if(name && data){
    //   //       updates[`/users/${getters.user.isSwitched?getIdAK(uid):uid}/info/` + name] = data;
    //   //     }else updates[`/users/${getters.user.isSwitched?getIdAK(uid):uid}/info/` + name] = rootState.cryptoModule[getters.current.infoName][name];
    //   //   }
    //   //   // console.log('updates',updates)
    //   //   await update(ref(db), updates);
    //   //   if(name!=='historyList'){//не показывать сообщ об обновлении истории
    //   //     commit('msgFB',{text: (getters.user.isSwitched?'ak ':'')+'updated'});
    //   //   }
    //   // }catch(error){
    //   //   commit('msgFB',{text:getters.user.isSwitched?'ak':''+' update failed:' + error});
    //   //   throw error
    //   // }
    // },
    async updateFBInfoList({ dispatch,commit,getters,state}, {listName, toAK}){
      // console.log('info :',rootState.cryptoModule[getters.current.infoName])
      try{
        const uid = getters.user.id
        const db = await getDatabase();
        const updates = {};
        if(toAK || getters.user.isSwitched){
          console.log('up AK')
          updates[`/users/${getIdAK(uid)}/info/` + listName] = getters.infoAK[listName];
        }else{
          if(getters.current.listName==='hypLists'){
            updates[`/users/${uid}/hypLists`] = getters.hypLists;
          }else
            // console.log(uid,listName)
            updates[`/users/${uid}/info/` + listName] = getters.infoMain[listName];
          // console.log('updates str:', `/users/${uid}/info/` + listName)
          // console.log('updates str:', getters.infoMain[listName])
        }
        await update(ref(db), updates);
        if(listName!=='historyList'){//не показывать сообщ об обновлении истории
          if(toAK || getters.user.isSwitched){
            commit('msgPush',{text: 'updated ak',type:'success'});
          }else {
            // console.log('listName up:',listName)
            commit('msgPush',{text: 'updated',type:'success'});
          }
        }
      }catch(error){
        commit('msgPush',{text:getters.user.isSwitched?'ak':''+' update failed:' + error,type:'error'});
        throw error
      }
    },
    async updateFBPath({ dispatch,commit,getters,state}, { path, data}){
      try{
        const uid = getters.user.id
        const db = await getDatabase();
        const updates = {};
        updates[`/users/${getters.user.isSwitched?getIdAK(uid):uid}/` + path] = data;
        await update(ref(db), updates);
        if(path!=='info/historyList'){//не показывать сообщ об обновлении истории
          commit('msgPush',{text: (getters.user.isSwitched?'ak ':'')+'updated',type:'success'});
        }
      }catch(error){
        commit('msgPush',{text:getters.user.isSwitched?'ak':''+' update failed:' + error,type:'error'});
        throw error
      }
    },
  }
}
