<template>
  <div class="panelContainer">
    <v-radio-group
      v-model="mode"
      inline
      hide-details
    >
      <v-radio
        label="user"
        value="user"
      ></v-radio>
      <v-radio
        label="redactor"
        value="redactor"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>

  import {defineComponent} from "vue";
  export default defineComponent( {
    data() {
      return {
        mode:this.$store.getters.user.mode
      };
    },
    computed:{

    },
    watch: {
      mode: {
        handler(){
          this.$store.commit('setMode',{mode:this.mode});
        },
        immediate:false,
      },
    },
    methods: {
    }
  })
</script>

<style scoped>
  .panelContainer{
    position: absolute;
    top:15px;
    left:20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    /*background-color: rgba(0, 0, 0, 0.8);*/
    /*box-shadow: 0 0 15px rgba(0,0,0,0.95);*/
    color: rgba(255, 255, 255, 0.8);
  }
  @media only screen and (max-width: 850px) {
    .panelContainer {
      display: none;
    }
  }

</style>
