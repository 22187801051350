<template>
  <div class="alarmContainerE">
    <ul class="list">
      <li v-for="item in cAlarmEventsListActivated" :key="item.id" class="listItem pr">
        <img src="/img/alarmItem.png" style='width:20px' alt="alarm ico"/>
        <span class="label fz12 ml10">{{item.date}}</span>
        <span class="curr ml10">{{item.comment}}</span>
        <button class="delBtn ml20" @click="delItem(item.id,'alarmEventsList')">x</button>
      </li>
    </ul>
  </div>
</template>

<script>
import makeUrlFromExchangeName from '../../helpers/makeUrlFromExchangeName';
import FilterBlock from '../Filter/FilterBlock';
import {defineComponent} from "vue";
import readableNumber from "@/mixins/readableNumber.mixin";

export default defineComponent( {
  components: { FilterBlock },
  mixins:[readableNumber],
  data(){
    return{
      isDropped:false,
      isEOpen:true,
      isPOpen:false,
      selectedExchange:'all',
    }
  },
  computed: {
    calarmActivatedList(){
      if(this.$store.getters.currentInfo.alarmAltsList){
        return this.$store.getters.currentInfo.alarmAltsList.filter(item=>{
          if(item.amount<0){
            return item.currentPrice>=item.desiredPrice
          }else return item.currentPrice<=item.desiredPrice
        })
      }else return []
    },
    cAlarmEventsListActivated(){
      if(this.$store.getters.currentInfo.alarmEventsList){
        return this.$store.getters.currentInfo.alarmEventsList.filter(item=>item.date<=this.$store.getters.updateDate)
      }else return []
    },
    cAlarmPotentialActivated(){
      let newList = this.$store.getters.currentInfo.potentialList||[];

      if (this.selectedExchange!=='all'){
        // if (this.$store.state.cryptoModule.filterTabLists.exchanges.selectedInAlarmPopup!=='all'){
        newList = newList.filter(item => item.exchange===this.selectedExchange)
      }
      return newList.filter(item=>item.currentPrice<item.desiredPrice)
    },
    cExchanges(){
      const totalItems = this.$store.getters.currentInfo.potentialList.filter(item=>item.currentPrice<item.desiredPrice)
      return ['all', ...new Set(totalItems.map(item => item.exchange))]
    }
  },
  methods:{
    makeUrl(name){
      const coinObjInTradeList = this.$store.getters.currentInfo.tradeList.find(item=>item.coin === name);
      const coinObjInPotentialList = this.$store.getters.currentInfo.potentialList.find(item=>item.coin === name);
      const exObj=coinObjInTradeList||coinObjInPotentialList||{}
      const ex=exObj.exchange||'no'
      return makeUrlFromExchangeName(ex,name)
    },
    delItem(id,list){
      const ok = confirm('delete alarm?')
      if(ok){
        this.$store.commit('deleteItem', { id, listName:list})
      }
    },
  },
})

</script>

<style scoped>

.alarmContainerE{
  position: fixed;
  top:15px;
  left:15px;
  z-index: 100000000;
  border-radius: 0 5px 5px 0 ;
  display: flex;
  overflow-x:hidden;
  max-height: 80%;
}

.delBtn{
  color: red;
  font-size: 10px;
}
.delBtn:hover{
  color: #42d69c;
  cursor: pointer;
}
.list{
  display: flex;
  flex-flow: column;
  overflow-y: hidden;
}

.listItem{
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 5px rgba(0,0,0,1);
  padding:10px;
  border-radius:5px;
  position:relative;
  margin-bottom:15px;
  text-decoration: none;
}
.listItem:last-child{
  margin-bottom:unset;
}


@media only screen and (max-width: 768px) {
}

</style>
