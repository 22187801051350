//
// export default {
//   data() {
//     return {
//       scrollPosition: window.scrollY
//     }
//   },
//   methods: {
//     get_scroll_position() {
//       window.addEventListener('scroll', () => {
//         this.scrollPosition = window.scrollY;
//       });
//       return this.scrollPosition
//     }
//   }
// }

export default{
  data() {
    return {
      scrollPosition: window.scrollY
    }
  },
  methods: {
    get_scroll_position() {
      window.addEventListener('scroll', () => {
        this.scrollPosition = window.scrollY;
        // this.$store.state.scrollPosition = window.scrollY;
      });
      return this.scrollPosition
    }
  },
}
