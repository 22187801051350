<template>
  <div class="cont">
    <transition-group name="sli-fade">
      <div v-for="item in this.$store.state.msgs" :key="item" v-if="this.$store.state.msgs.length" >
        <div class="box mr10" :class="item.type" v-if="item.text!=='updated' && item.text!=='updated ak' ">
          {{item.text}}
        </div>
        <div class="boxik mr10" :class="item.type" v-if="item.text==='updated' || item.text==='updated ak'">
          {{item.text === 'updated' ? 'up': item.text === 'updated ak' ? 'up ak' : item.text}}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>

</script>

<style scoped>
  .cont{
    position: fixed;
    top:15px;
    left:50%;
    right:50%;
    z-index: 10000;
    display: flex;
    /*flex-flow: column;*/
    justify-content: center;
    align-items: center;
  }
  .box{
    /*transform: translateX(-50%);*/
    transition: all 0.2s ease;
    padding:.75rem;
    border-radius:.5rem;
    min-width: 250px;
    overflow-wrap:break-word;
    color:rgb(255 255 255);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .boxik{
    /*transform: translateX(-50%);*/
    transition: all 0.2s ease;
    /*padding:.75rem;*/
    border-radius:5px;
    min-width: 50px;
    /*overflow-wrap:break-word;*/
    color:rgb(255 255 255);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .success{
    background-color: rgba(21 128 61);
  }
  .error{
    background-color:rgb(185 28 28);
  }
  .warning{
    background-color:rgb(161 98 7);
  }
  .sli-fade-enter-active {
    opacity: 0;
  }
  .sli-fade-leave-to{
    transition: all 0.2s ease;
    transform: translateY(-50px);
    opacity: 0;
  }

  @media only screen and (max-width: 768px) {

  }

</style>
