<template>
  <div class="loaderBox">
    <img src="/img/loading10.gif" alt="loading" class="img"/>
  </div>
</template>

<script>
  import {defineComponent} from "vue";

  export default defineComponent( {
  })
</script>

<style scoped>
  .loaderLayout {
    /*background-image: url("/public/img/fon6.jpg");*/
    /*background-size: cover;*/
    /*min-height: 100vh;*/
    /*background-attachment:fixed;*/
    /*background-position: center top;*/
  }
  .loaderBox{
    /*position: fixed;*/
    /*top:15%;*/
    /*left: 48%;*/
    background-color: black;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img{
    width:100px;
  }

  @media only screen and (max-width: 768px) {
    .img{
      width:50px;
    }
  }

</style>
