import { createRouter, createWebHistory } from "vue-router";
import { getAuth } from 'firebase/auth';
import store from "../store/";
const routes = [//приоритет сверху вниз
  {
    path: '/',
    name: 'main',
    meta: {layout: 'main', auth:true, title:'home'},
    component: () => import('../views/mainViews/Main.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {layout: 'login'},
    component: () => import('../views/loginViews/Auth.vue')
  },
  {
    path: '/alarm',
    name: 'alarm',
    meta: {layout: 'main', auth:true, title:'alarm'},
    component: () => import('../views/mainViews/Alarm.vue')
  },
  {
    path: '/notes',
    name: 'notes',
    meta: {layout: 'main', auth:true, title:'notes'},
    component: () => import('../views/mainViews/Notes.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: {layout: 'main', auth:true},
    component: () => import('../views/mainViews/History.vue')
  },
  {
    path: '/fund',
    name: 'fund',
    meta: {layout: 'main', auth:true},
    component: () => import('../views/mainViews/Fund.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {layout: 'main', auth:true},
    component: () => import('../views/mainViews/Settings.vue')
  },
  {
    path: '/weight',
    name: 'weight',
    meta: {layout: 'main', auth:true},
    component: () => import('../views/mainViews/Weight.vue')
  },
  {
    path: '/lab',
    name: 'lab',
    meta: {layout: 'main', auth:true},
    component: () => import('../views/mainViews/Lab.vue')
  },
  {
    path: '/reserve',
    name: 'reserve',
    meta: {layout: 'main', auth:true},
    component: () => import('../views/mainViews/Reserve.vue')
  },
  {
    path: '/last',
    name: 'last',
    meta: {layout: 'main', auth:true},
    component: () => import('../views/mainViews/Last.vue')
  },
  {
    path: '/expenses',
    name: 'expenses',
    meta: {layout: 'main', auth:true},
    component: () => import('../views/mainViews/Expenses.vue')
  },
  {
    path: '/resume',
    name: 'resume',
    meta: {layout: 'resume'},
    component: () => import('../views/resumeViews/Resume.vue')
  },
  // {
  //   path: '/beloe/:subLinkName',
  //   name: 'subLinkName',
  //   meta: {layout: 'beloe'},
  //   component: () => import('../views/beloeViews/Beloe.vue'),
  // },
  // {name:'product', component: () => import('../views/emptyViews/Beloe.vue'), path : '/product/:id'},
  // {
  //   path: '/*',
  //   name: 'empty',
  //   meta: {layout: 'empty'},
  //   component: () => import('../views/emptyViews/NotFound.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//Защита роута. Выполняется перед кажд сменой роута
router.beforeEach((to,from,next)=>{
  const currentUser = getAuth().currentUser//если есть то юзер находится в системе
  // console.log('currentUser',currentUser)
  // if(to.path.==='/beloe/cosmo' && !currentUser){
  //   alert('Only for registered users')
  //   next('/beloe')
  // }
  const isNeedAuth = to.matched.some(item=>item.meta.auth)//стоит ли галка auth на определенном роуте?
  // console.log('to:',to)
  // console.log('isNeedAuth',isNeedAuth)
  // console.log('router need auth?:',isNeedAuth)
  // if(to.path==='/beloe/cosmo' && !currentUser.emailVerified){
  if(isNeedAuth && !currentUser){
    return next('/login')//переход на страницу авторизации
  }else if(isNeedAuth && !currentUser.emailVerified){
    store.commit('msgPush', { text:'Please verify your email', type:'warning'});
    // next('/login?message=login')//переход на страницу авторизации
    return next('/')
  }else {
    window.scrollTo({top: 0, behavior: 'smooth'});
    return next()//обычный переход
  }
})
export default router
