import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Trend from 'vue3trend'//https://thewebdev.info/2020/09/06/add-sparklines-to-our-vue-app-with-vue-trend/
// import Sticky from 'vue-sticky-directive';
// import cors from 'cors';
// import VueClipboard from 'vue-clipboard2';//https://www.npmjs.com/package/vue-clipboard2
loadFonts();
import { install } from "resize-observer";//для стар версий браузера safari
// Polyfill for legacy browsers https://caniuse.com/resizeobserver
if (!window.ResizeObserver) install();
// import sharp from "sharp";
import {FBconfig} from "@/utils/firebaseConfig";
import axios from "axios";
// axios.defaults.baseURL = process.env.BASE_URL;
// axios.defaults.headers.get['Accepts'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';

const iApp = initializeApp(FBconfig);
// getAnalytics(iApp);
const authCredentials = getAuth(iApp);
let app;
onAuthStateChanged(authCredentials,(user)=>{//если авторизован то рендер всего приложения
    if(!app){//избежать рендера кажд раз при логине и логауте. Инициализировано ли уже приложение?

        app = createApp(App)
            .use(router)
            .use(store)
            .use(vuetify)
            .use(Trend)
            .mount("#app");
    }
    // createApp(App).use(store).use(router).mount('app')
})



