<template>
  <div>
    <component :is="($route.meta.layout || 'empty') + '-layout'" >
      <router-view/>
    </component>
<!--    <MsgFB/>-->
    <Msg/>
    <BtnUp/>
  </div>
</template>

<script>
  import LoginLayout from '@/layouts/LoginLayout'
  import MainLayout from '@/layouts/MainLayout'
  import EmptyLayout from '@/layouts/EmptyLayout'
  import ResumeLayout from '@/layouts/ResumeLayout'
  // import MsgFB from '@/components/MsgFB'
  import Msg from '@/components/Msg'
  import BtnUp from '@/components/BtnUp'
  import {defineComponent} from "vue";
  export default defineComponent({
  name: "App",
    // metaInfo: {
    //   // if no subcomponents specify a metaInfo.title, this title will be used
    //   title: 'Default Title',
    //   // all titles will be injected into this template
    //   titleTemplate: '%s | My Awesome Webapp'
    // },
    // created () {
    //   document.title = "Look Ma!";
    // },
    components: {
      LoginLayout,
      MainLayout,
      EmptyLayout,
      ResumeLayout,
      // MsgFB,
      Msg,
      BtnUp
    },
  data: () => ({
  }),

  mounted () {
    // console.log('vuetify mobile?',this.$vuetify.display.mobile)
    // console.log('route:',this.$route)
  }
})
</script>
<style>
  /*@import '~materialize-css/dist/css/materialize.min.css';*/
  /*@import '../src/assets/index.css';*/
  @import 'assets/index.css';
  @import 'assets/reset.css';
</style>
