<template>
  <div class="emptyLayout" :style="{ backgroundImage:cFonUrl }">
    <h1 class="title">
      <span>404 error:</span>
      <span>Page not found</span>
    </h1>
<!--<img src="https://res.cloudinary.com/teepublic/image/private/s&#45;&#45;VSJH4e94&#45;&#45;/t_Preview/b_rgb:191919,c_lpad,f_jpg,h_630,q_90,w_1200/v1489924450/production/designs/1334908_1.jpg" class="img" alt="no page found" >-->
  </div>
<!--  <img src="https://i.ytimg.com/vi/Q8Z9q4p-OvY/maxresdefault.jpg" alt="no page found" style="width:100%; height: 100vh">-->
<!--  <img src="https://bootstraptema.ru/error404/2017/light404/images/404.gif" alt="no page found" style="width:100%; height: 100vh">-->

</template>

<script>
  export default  {
    name: 'empty-layout',
    data:()=>({
    }),
    computed:{
      cFonUrl(){
      }
    },
  }
</script>

<style scoped>

  .img{
    width:100%;
    height: 100vh;
  }
  .title{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .title>span{
    font-size: 60px;
    /*font-family: 'Press Start 2P', cursive;*/
    margin-bottom: 20px;
  }
  .emptyLayout {
    /*background-image: url("https://ottenki.com.ua/media/cache/product_holder/037397733af1faa89cebc9c504987c22.jpg");*/
    background-size: cover;
    min-height: 100vh;
    background-attachment:fixed;
    background-position: center top;
    padding: 20px;
  }
  @media only screen and (max-width: 850px) {
    .title>span{
      font-size: 20px;
    }
  }

</style>

<!--// console.log('mainLayout mounting. Loading all existing coins...')-->
<!--// await this.$store.dispatch('loadAllExistingCoinsNames');//потом загр имена всех альтов, около 9000шт, краткое инфо.-->
<!--// await this.$store.commit('loadLocal');//загр все локальное-->

<!--watch:{//ошибка в сторе есть то выводим на экран через мессаге-->
<!--error(fbError){-->
<!--// this.$error(messages[fbError.code]||'Smth wrong')-->
<!--// alert(messages[fbError.code]||'какая-то хрень')-->
<!--this.$store.commit('msgPush',{text:fbError})-->
<!--},-->
<!--},-->
