<template>
  <div class="emptyLayout">
    <Navbar/>
    <main class="mainContainer">
      <router-view/>
    </main>
  </div>
</template>

<script>
  import Navbar from "@/components/app/Navbar";
  export default {
    components: { Navbar},
  }
</script>

<style scoped>
  /*<!--    :style="{ backgroundImage: $store.state.fonEmptyPermanentUrl}"-->*/

  .emptyLayout {
    background-image: url("/public/img/fon6.jpg");
    background-size: cover;
    min-height: 100vh;
    background-attachment:fixed;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainContainer {
    max-width:1600px;
    margin: 0 auto;
    padding: 75px 0 0 0;
  }

  @media only screen and (max-width: 768px) {

  }

</style>
