<template>
  <section>
    <div class="sortItem" v-if="type==='label' && $store.getters.current.listName==='potentialList'">
      <span class="coinBox hoverLight" :class="{'lightened':$store.getters.current.filter[type] === 'coin'}" @click="selectFilter('coin')">A-Z</span>
      <span class="percBox hoverLight" :class="{'lightened':$store.getters.current.filter[type] === 'priceChangePercentage24h'}" @click="selectFilter('priceChangePercentage24h')" >24h%</span>
      <span class="coinBox">des price</span>
      <span class="currBox">c price</span>
      <span class="coinBox">dum pr</span>
      <span class="xsBox hoverLight hideMob" :class="{'lightened':$store.getters.current.filter[type] === 'dxs'}" @click="selectFilter('dxs')">dX's</span>
      <span class="dumpBox hideMob">ATH price</span>
      <span class="xsBox hoverLight hideMob" :class="{'lightened':$store.getters.current.filter[type] === 'axs'}" @click="selectFilter('axs')">aX's</span>
      <span class="mcBox hoverLight hideMob" :class="{'lightened':$store.getters.current.filter[type] === 'marketCapRank'}" @click="selectFilter('marketCapRank')">MC#</span>
      <span class="graphBox hideMob">7d graph</span>
      <span class="commentBox input hideMob">comment (editable)</span>
    </div>

    <div class="sortItem" v-if="type==='label' && $store.getters.current.listName!=='potentialList'">
      <span class="coinBox hoverLight" :class="{'lightened':$store.getters.current.filter[type] === 'coin'}" @click="selectFilter('coin')">A-Z</span>
      <span class="amBox hideMob">amount</span>
      <span class="invBox hoverLight" :class="{'lightened':$store.getters.current.filter[type] === 'invested'}" @click="selectFilter('invested')">inv</span>
      <span class="plBox hoverLight" :class="{'lightened':$store.getters.current.filter[type] === 'profit'}" @click="selectFilter('profit')">p/l</span>
      <span class="nowBox hoverLight" :class="{'lightened':$store.getters.current.filter[type] === 'valueNow'}" @click="selectFilter('valueNow')">now</span>
      <span class="percBox hoverLight" :class="{'lightened':$store.getters.current.filter[type] === 'priceChangePercentage24h'}" @click="selectFilter('priceChangePercentage24h')" >24h%</span>
      <span class="currBox hideMob">c price</span>
      <span class="avBox hideMob">av price</span>
      <span class="xsBox hoverLight" :class="{'lightened':$store.getters.current.filter[type] === 'xs'}" @click="selectFilter('xs')">X's</span>
      <span class="dumpBox hideMob">d price</span>
      <span class="xsBox hoverLight hideMob" :class="{'lightened':$store.getters.current.filter[type] === 'dxs'}" @click="selectFilter('dxs')">dX's</span>
      <span class="dumpBox hideMob">ATH price</span>
      <span class="xsBox hoverLight hideMob" :class="{'lightened':$store.getters.current.filter[type] === 'axs'}" @click="selectFilter('axs')">aX's</span>
      <span class="fxdBox hideMob">fxd p/l</span>
      <span class="mcBox hoverLight hideMob" :class="{'lightened':$store.getters.current.filter[type] === 'marketCapRank'}" @click="selectFilter('marketCapRank')"  v-if="this.$store.getters.current.listName!=='hypLists'">MC#</span>
      <span class="graphBox hideMob">7d graph</span>
      <span class="commentBox input hideMob">comment (editable)</span>
    </div>

    <ul class="filterContainer" v-else>
      <li
        class="filterItem hoverLight mr10"
        :class="{'lightened':$store.getters.current.filter[type] === item}"
        v-for="(item,index) in listStr"
        :key="index"
        @click="selectFilter(item)"
      >
        {{item}}
      </li>
    </ul>
  </section>
</template>

<script>
  import {defineComponent} from "vue";
  export default defineComponent( {
    props:['listStr','type'],
    data() {
      return {
      };
    },
    methods: {
      selectFilter(t){
        // this.$store.commit('setFilter',{type:this.type,item:item})
        // console.log(t)
        this.$store.commit('setLvl',{lvl1:'current',lvl2:'filter',lvl3:this.type, data:t})
      },
      setDumpPrices(){
        this.$store.commit('setDumpPrices')
      },
    },
  })
</script>

<style scoped>
@import '@/assets/listItemBoxes.css';
  .filterContainer{
    margin-bottom: 10px;
    padding-left:0;
  }
  .filterItem{
    color: rgba(255, 255, 255, 0.4);
    font-size: 16px;
    display: inline-block;
  }

  .sortItem{
    color: rgba(255, 255, 255, 0.4);
    border-bottom:1px solid rgba(255, 255, 255, 0.4);
    font-size: 16px;
    margin-top: 10px;
    background-color: transparent;
    display: inline-block;
  }
  .sortItem >span{
    display: inline-block;
    color:rgba(255, 255, 255, 0.4);
  }

  @media only screen and (max-width: 768px) {
    .filterItem{
      font-size: 12px;
    }
    .sortItem{
      font-size: 10px;
      margin-top: 20px;
      padding-bottom: 10px;
    }
    .sortItem>span{
      width: unset;
      margin-right: 15px;
      display: unset;
    }
    .filterItem{
      font-size: 12px;
    }

  }

</style>
