<template>
  <nav class="navContainer myWhite" :style="cStyle">
    <div class="menu hideDesk" @click="isSideMenuOpen=!isSideMenuOpen">&equiv;</div>
<!--    <span class="cryptoLogo hideMob hover" @click="this.$store.dispatch('loadMyGeckoCoins')">My site</span>-->
<!--    <div class="loaderCountdown hideMob" v-if="this.$store.getters.isCLoading.gecko && $route.matched[0].path!=='/resume'">-->
<!--      <img src="/img/loading10.gif" style='width:100%;position:absolute;' alt="loading"/>-->
<!--    </div>-->
<!--    <LoaderCountdown class="loaderCountdown hideMob" v-if="!$store.getters.isCLoading.gecko && $route.matched[0].path!=='/resume'" :key="componentKeyForForceRerender"/>-->

    <router-link v-for="item in cNavItemsArr" :to="item.linkName" class="menuItem hoverLight hideMob mr20" exact-active-class="active">
      {{item.title}}
    </router-link>

    <router-link to="notes" class="notesButton hover" v-if="this.$store.getters.user.isAorK && $route.matched[0].path!=='/resume'">
      <img src="/img/notes.png" alt="notes" style='width:100%'/>
    </router-link>

<!--    <router-link to="cosmo" class="cosmoButton hover" v-if="this.$store.getters.user.isAorK && $route.matched[0].path!=='/beloe'">-->
<!--      <img src="/img/cosmo.png" alt="cosmo" style='width:100%'/>-->
<!--    </router-link>-->

<!--    <router-link to="/beloe" class="homeButton hover hideDesk" >-->
<!--      <img src="/img/home.png" alt="cosmo" style='width:100%'/>-->
<!--    </router-link>-->

<!--    <div class="hoverLight fz12 w ml20 hideMob" style="padding-top:5px"  @click="logout">Logout</div>-->
<!--    <div class="timeContainer hideMob">-->
<!--      <span>San Francisco: {{dateSF}}</span>-->
<!--      <br/>-->
<!--      <span>Moscow: {{dateMS}}</span>-->
<!--      <span class="timeDrop">-->
<!--        <br/>-->
<!--        <span>EST: {{dateEST}}</span>-->
<!--        <br/>-->
<!--        <span>UTC: {{dateUTC}}</span>-->
<!--      </span>-->
<!--    </div>-->
    <div class="userContainer">
<!--      <span @click="clickLetter" class="hover mr20" v-if="this.$store.getters.user.isAorK && $route.matched[0].path!=='/resume'">-->
<!--        {{$store.getters.user.letter}}-->
<!--      </span>-->
      <UserBlock @click="isSideOpen = !isSideOpen" v-if="$route.matched[0].path!=='/resume'"/>
    </div>

<!--    side right-->
    <div class="shade" v-if="isSideMenuOpen || isSideOpen"/>
    <div class="sideUser card " :class="{'open': isSideOpen}">
      <SideLogin v-model:is-open="isSideOpen" v-if="isSideOpen && !$store.getters.user.id" v-click-outside="()=>isSideOpen=false" />
      <SideProfile v-model:is-open="isSideOpen" v-if="isSideOpen && $store.getters.user.id" v-click-outside="()=>isSideOpen=false"/>
    </div>
<!--    side left-->
    <div class="sideMenu card " :class="{'open': isSideMenuOpen}">
      <SideMenu :c-nav-items-arr="cNavItemsArr" v-model:is-open="isSideMenuOpen" v-if="isSideMenuOpen" v-click-outside="()=>isSideMenuOpen=false" />
    </div>

<!--    <Transition name="slide-fade">-->
<!--      <div class="menuContainer card" v-if="isMenuOpen"  v-click-outside="onClickOutside" :style="'background-color:'+$store.state.beloeInfo.service.colors.navbarBgColor">-->
<!--        <router-link v-for="item in cNavItemsArr" :to="item.linkName" class="menuItemMob hoverLight" style="margin-bottom: 10px;" @click="isMenuOpen=false">-->
<!--          {{item.title}}-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </Transition>-->
    <AlarmEventActive v-if="this.$store.getters.user.isAorK"/>
  </nav>
</template>

<script>
  import clickOutside from '../../directives/clickOutside'
  import LoaderCountdown from '../LoaderCountdown';
  import AlarmEventActive from '../Alarm/AlarmEventActive';
  import UserBlock from '../Beloe/UserBlock';
  import SideLogin from '../Beloe/SideLogin';
  import SideProfile from '../Beloe/SideProfile';
  import {defineComponent} from "vue";
  import SideMenu from "@/components/Beloe/SideMenu";
  import RedactorPanel from "@/components/RedactorPanel";
  export default defineComponent( {
    components: {SideMenu, AlarmEventActive, LoaderCountdown, UserBlock,SideProfile,SideLogin,RedactorPanel},
    directives: {clickOutside},
    data: () => ({
      dateSF: null,
      dateMS: null,
      dateEST: null,
      dateUTC: null,
      intervalAlarm:null,
      interval:null,//in mounted we record this interval
      dropdown:null,
      isMenuOpen:false,
      isSideOpen:false,
      isSideMenuOpen:false,
      isMenuOpenByButton:false,//фикс закрывания сразу
      componentKeyForForceRerender:0,

    }),
    methods:{
      clickLetter(){
        this.$store.commit('switchInfo')
        // this.$store.commit('switchAK')
      },
      toOpen(){
        this.isMenuOpen = !this.isMenuOpen
        this.isMenuOpenByButton = true//фикс закрывания сразу
      },
      toClose(){
        this.isMenuOpen = false
        this.isMenuOpenByButton = false
      },
      onClickOutside (event) {
        if(this.isMenuOpen && !this.isMenuOpenByButton){
          return this.isMenuOpen = !this.isMenuOpen;
        }
        this.isMenuOpenByButton = false//фикс закрывания сразу. Возврат в первонач состояние.
      },
    },
    computed:{
      cStyle(){
        return `background-color:${this.$store.getters.currentInfo.service?.colors?.navbarBgColor || 'rgba(0,0,0, 0.8)'}`
      },
      cNavItemsArr(){
        if(this.$route.matched[0].path==='/resume' && !this.$store.getters.user.id) {//гость резюме
          return [
            {title: 'Resume', linkName: 'resume'},
          ]
        }else if(this.$store.getters.user.isA){// А crypto
          return [
            {title:'Main', linkName:'/'},
            {title:'Alarm', linkName:'alarm'},
            {title:'Fund', linkName:'fund'},
            // {title:'History', linkName:'history'},
            {title:'Weight', linkName:'weight'},
            {title:'Settings', linkName:'settings'},
            {title:'Lab', linkName:'lab'},
            {title:'Resume', linkName:'resume'},
            {title:'Daily', linkName:'expenses'},
          ]
        }else if(this.$store.getters.user.isK){// K crypto
          return [
            {title:'Main', linkName:'/'},
            {title:'Alarm', linkName:'alarm'},
            {title:'Fund', linkName:'fund'},
            {title:'Weight', linkName:'weight'},
            {title:'Settings', linkName:'settings'},
          ]
        }else{//all crypto
          return [
            {title:'Main', linkName:'/'},
            {title:'Alarm', linkName:'alarm'},
            {title:'Settings', linkName:'settings'},
          ]
        }
      },
    },
    mounted () {
      this.interval = setInterval(()=>{
        this.dateSF= new Date().toLocaleString("ru-Ru", {timeZone: "America/los_angeles"})
        this.dateMS= new Date().toLocaleString("ru-RU", {timeZone: "Europe/Moscow"})
        this.dateEST= new Date().toLocaleString("ru-RU", {timeZone: "EST"})
        this.dateUTC= new Date().toLocaleString("ru-RU", {timeZone: "UTC"})
      },1000)

      // this.dropdown = M.Dropdown.init(this.$refs.dropdown,{
      //   constrainWidth:true
      // })
    },
    watch: {//при ручном изменении урла срабатывает рендер. При первом рендере и при послед изменениях.
      '$route.name': {
        handler(){//завод опция "что сделать"
          this.toClose();
        },
        immediate:false,//= метод created() сработает с таким же содержимым (при первом рендере). Можно удалить оригинальный метод created() .
      },
      '$store.getters.isGeckoLoading': {
        handler(){
          this.componentKeyForForceRerender=this.componentKeyForForceRerender+1
        },
        immediate:false,
      },
    },

    beforeUnmount () {//очистить обновление времени из памяти при исчезновении панели Navbar
      clearInterval(this.interval)
    }
  })
</script>
<style scoped>
  /*@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');*/
  .navContainer {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:75px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: rgba(0,0,0,0.9);*/
    /*padding: 20px 0;*/
    border-bottom:1px solid rgba(255, 255, 255, 0.1);
    /*box-shadow: 0 0 10px rgba(0,0,0,0.95);*/
    z-index: 1000;
  }
  .userContainer {
    position: absolute;
    top:12px;
    right:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .cryptoLogo{
    font-family: 'Press Start 2P', cursive;
    font-size: 12px;
    position:absolute;
    top:30px;
    left:50px;
    color:rgba(255,255,255, 0.7);
  }
  .shade{
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.7);
  }
  .loaderCountdown{
   position: absolute;
   top:20px;
   left:180px;
   width:35px;
 }
  .switchContainer{
    font-size: 22px;
    position:absolute;
    top:20px;
    right:180px;
  }
  .notesButton{
    position: absolute;
    top:20px;
    right:300px;
    width:40px;
  }
  .cosmoButton{
    position: absolute;
    top:20px;
    right:370px;
    width:40px;
  }
  .homeButton{
    position: absolute;
    top:20px;
    right:190px;
    width:32px;
  }
  .sideUser{
    position:fixed;
    right:0;
    top:0;
    background-color: rgba(0,0,0,0.9);
    z-index:5;
    /*overflow-y:auto;*/
    /*will-change:transform;*/
    /*-webkit-backface-visibility:hidden;*/
    /*backface-visibility:hidden;*/
    /*-webkit-transform:translateX(-105%);*/
    transform:translateX(100%);
    transition:transform .2s;
  }

  .sideUser.open{
    /*-webkit-transform:translateX(0);*/
    transform:translateX(0)
  }
  .sideMenu{
    position:fixed;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.9);
    z-index:5;
    transform:translateX(-100%);
    transition:transform .2s;
  }

  .sideMenu.open{
    transform:translateX(0)
  }

  .timeContainer {
    position: absolute;
    top:15px;
    right:80px;
    /*z-index: 5;*/
    text-align: right;
    color:rgba(255, 255, 255, 0.6);
    font-family: "Tahoma";
    font-size: 12px;
    padding: 5px;
    transform:scale(1);
    transition:transform .2s;
  }
  .timeDrop{
    display:none;
  }
  .timeContainer:hover{
    cursor: pointer;
    transform:scale(2) translateX(-28%) translateY(28%);
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
  }
  .timeContainer:hover .timeDrop {
    display: unset;
  }
  .menu{
    position: absolute;
    top:15px;
    left:0;
    line-height: 40px;
    font-size: 60px;
    padding-left: 20px;
    color:rgba(255, 255, 255, 0.8);
  }
  .menu:hover{
    cursor:pointer;
  }
  .menuItem{
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
    font-size: 20px;
    /*margin-right:30px;*/
  }
  .menuItemMob{
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
    font-size: 20px;
    /*margin-bottom:10px;*/
  }

  .menuContainer{
    width:150px;
    position: absolute;
    top:70px;
    left:0;
    display: flex;
    flex-flow: column;
    padding: 20px;
    z-index: 1000;
  }
  .logout{
    color:rgba(255, 255, 255, 0.4);
    font-size: 12px;
    margin-top: 20px;
  }
  .active{
    border-bottom:1px solid rgba(255, 255, 255, 0.9);
    color:rgba(255, 255, 255, 0.8);
  }

  @media only screen and (max-width: 1200px) {
    .menuItem{
      font-size: 14px;
      margin-right:10px;
    }
    .beloeLogo{
      top:20px;
      left:20px;
      width:150px
    }
    .notesButton{
      top:25px;
      right:200px;
      width:25px;
    }
    .homeButton{
      top:20px;
      right:190px;
      width:32px;
    }
    .switchContainer{
      font-size: 18px;
      top:25px;
      right:170px
    }
  }
  @media only screen and (max-width: 768px) {
    .loaderCountdown{
      left:100px;
    }
    .navContainer{
      padding:0;
    }
    .timeContainer {
      right:20px;
    }
    .homeButton{
      right:230px;
    }
    /*.menuContainer{*/
    /*  left:unset;*/
    /*  right:0;*/
    /*}*/
    /*.menu{*/
    /*  left:unset;*/
    /*  right:20px;*/
    /*}*/
    .timeContainer {
      right:unset;
      text-align: right;
      left:20px;
    }
    .cosmoButton{
      top:25px;
      right:90px;
      width:25px;
    }
    .timeContainer{
      font-size: 10px;
    }

  }

</style>
