export default function getIdAK(uid){

  if(uid === process.env.VUE_APP_FIREBASE_A_ID){
    return process.env.VUE_APP_FIREBASE_K_ID
  }
  if(uid === process.env.VUE_APP_FIREBASE_K_ID){
    return process.env.VUE_APP_FIREBASE_A_ID
  }
}

// if(uid === '41X5bu9F9TZavk2hjFk6RwdgYEt1'){
//   return '03mDEF8mmladFMeSqUuJTVYpaiC2'
// }
// if(uid === '03mDEF8mmladFMeSqUuJTVYpaiC2'){
//   return '41X5bu9F9TZavk2hjFk6RwdgYEt1'
// }
// return uid
// }
